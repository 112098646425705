import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {CustomDatePipe} from '@portal/shared/pipes/custom-date-pipe';
import {NotFoundComponent} from '@portal/shared/not-found/not-found.component';
import {RouterModule} from '@angular/router';
import {OrganatizationTypePipe} from "@portal/shared/pipes/organatization-type-pipe";
import {SalesStatePipe} from "@portal/shared/pipes/sales-state-pipe";
import {SharedMatTableModule} from "@portal/shared/shared-mat-table.module";
import {GroupSelectionModalComponent} from "@portal/shared/group-selection-modal/group-selection-modal.component";
import {
    CollaboratorsSelectionModalComponent
} from "@portal/shared/collaborators-selection-modal/collaborators-selection-modal.component";
import {MatRadioModule} from "@angular/material/radio";
import {DonationsReportModalComponent} from "@portal/shared/donations-report-modal/donations-report-modal.component";
import {
    EventDrawerHeaderComponent
} from "../modules/admin/party/events/event-details/components/event-drawer-header/event-drawer-header.component";
import {
    EditAddDrawerHeaderComponent
} from "../modules/admin/shared/components/edit-add-drawer-header/edit-add-drawer-header.component";
import {TableComponent} from "@portal/shared/table/table.component";
import {ListPageBaseDirective} from "@portal/shared/base/list-page-base.directive";
import {SponsorsReportModalComponent} from "@portal/shared/sponsors-report-modal/sponsors-report-modal.component";
import {
    ActivityReportModalComponent
} from "@portal/shared/modals/activity-report-modal/activity-report-modal.component";
import { TransactionCategoryNamePipe } from './pipes/transaction-category-name';
import {FormatDatePipe} from "@portal/shared/pipes/format-date.pipe";
import {TagSelectorComponent} from "@portal/shared/tag-selector/tag-selector.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatMenuModule} from "@angular/material/menu";
import {
    DeleteConfirmationDialogComponent
} from "@portal/shared/delete-confirmation-dialog/delete-confirmation-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule,
        SharedMatTableModule,
        MatRadioModule,
        DragDropModule,
        MatMenuModule
    ],
    exports: [
        TableComponent,
        EditAddDrawerHeaderComponent,
        EventDrawerHeaderComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomDatePipe,
        OrganatizationTypePipe,
        SalesStatePipe,
        TransactionCategoryNamePipe,
        FormatDatePipe,
        TagSelectorComponent,

    ],
    declarations: [
        ListPageBaseDirective,
        TableComponent,
        EditAddDrawerHeaderComponent,
        EventDrawerHeaderComponent,
        DeleteDialogComponent,
        CustomDatePipe,
        NotFoundComponent,
        OrganatizationTypePipe,
        SalesStatePipe,
        GroupSelectionModalComponent,
        CollaboratorsSelectionModalComponent,
        TransactionCategoryNamePipe,
        DonationsReportModalComponent,
        SponsorsReportModalComponent,
        ActivityReportModalComponent,
        FormatDatePipe,
        TagSelectorComponent,
        DeleteConfirmationDialogComponent
    ]
})
export class SharedModule {
}
