declare function gtag(...args: any[]): void;

// src/app/services/gtm.service.ts
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GTMService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {}

    initializeGTM(): void {
        if (!environment.gtmId) {
            console.log('GTM not loaded - Development environment');
            return;
        }

        // Initialize dataLayer
        const dataLayerScript = this.document.createElement('script');
        dataLayerScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.gtmId}', {
        send_page_view: false
      });
    `;

        // Create GTM script
        const gtmScript = this.document.createElement('script');
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtmId}`;

        // Add scripts to head
        this.document.head.appendChild(gtmScript);
        this.document.head.appendChild(dataLayerScript);

        // Track route changes
        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event) => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'page_view', {
                    page_path: event.urlAfterRedirects,
                    page_title: this.document.title
                });
            }
        });
    }
}
