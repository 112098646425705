import {Component, OnInit} from '@angular/core';
import {GTMService} from "@portal/shared/services/gmt-service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private gtmService: GTMService) {}

    ngOnInit() {
        this.gtmService.initializeGTM();
    }
}
